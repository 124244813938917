import { render, staticRenderFns } from "./AlertSidebar.vue?vue&type=template&id=402c3650&scoped=true"
import script from "./AlertSidebar.vue?vue&type=script&lang=js"
export * from "./AlertSidebar.vue?vue&type=script&lang=js"
import style0 from "./AlertSidebar.vue?vue&type=style&index=0&id=402c3650&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "402c3650",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VForm,VSwitch})
